/**
 * Chameleon - Soluções visuais para projetos da Nexaas
 * Version: 1.1.0
 * Homepage: http://docs.chameleon.nexaas.com/
 * Licensed under MIT
 */
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@-o-keyframes bounceOutDown {
  0% {
    transform: translateY(0)
  }
  20% {
    opacity: 1;
    transform: translateY(-20px)
  }
  to {
    opacity: 0;
    transform: translateY(2000px)
  }
}

@-moz-keyframes bounceOutDown {
  0% {
    transform: translateY(0)
  }
  20% {
    opacity: 1;
    transform: translateY(-20px)
  }
  to {
    opacity: 0;
    transform: translateY(2000px)
  }
}

@-webkit-keyframes bounceOutDown {
  0% {
    transform: translateY(0)
  }
  20% {
    opacity: 1;
    transform: translateY(-20px)
  }
  to {
    opacity: 0;
    transform: translateY(2000px)
  }
}

@keyframes bounceOutDown {
  0% {
    transform: translateY(0)
  }
  20% {
    opacity: 1;
    transform: translateY(-20px)
  }
  to {
    opacity: 0;
    transform: translateY(2000px)
  }
}

@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px)
  }
  60% {
    opacity: 1;
    transform: translateY(-30px)
  }
  80% {
    transform: translateY(10px)
  }
  to {
    transform: translateY(0)
  }
}

@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px)
  }
  60% {
    opacity: 1;
    transform: translateY(-30px)
  }
  80% {
    transform: translateY(10px)
  }
  to {
    transform: translateY(0)
  }
}

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px)
  }
  60% {
    opacity: 1;
    transform: translateY(-30px)
  }
  80% {
    transform: translateY(10px)
  }
  to {
    transform: translateY(0)
  }
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px)
  }
  60% {
    opacity: 1;
    transform: translateY(-30px)
  }
  80% {
    transform: translateY(10px)
  }
  to {
    transform: translateY(0)
  }
}

@-o-keyframes progress-active {
  0% {
    width: 0;
    opacity: .3
  }
  50% {
    opacity: .8
  }
  to {
    opacity: .1;
    width: 100%
  }
}

@-moz-keyframes progress-active {
  0% {
    width: 0;
    opacity: .3
  }
  50% {
    opacity: .8
  }
  to {
    opacity: .1;
    width: 100%
  }
}

@-webkit-keyframes progress-active {
  0% {
    width: 0;
    opacity: .3
  }
  50% {
    opacity: .8
  }
  to {
    opacity: .1;
    width: 100%
  }
}

@keyframes progress-active {
  0% {
    width: 0;
    opacity: .3
  }
  50% {
    opacity: .8
  }
  to {
    opacity: .1;
    width: 100%
  }
}

@-o-keyframes progress-overlay {
  0% {
    opacity: .7;
    width: 0
  }
  to {
    opacity: 0;
    width: 100%
  }
}

@-moz-keyframes progress-overlay {
  0% {
    opacity: .7;
    width: 0
  }
  to {
    opacity: 0;
    width: 100%
  }
}

@-webkit-keyframes progress-overlay {
  0% {
    opacity: .7;
    width: 0
  }
  to {
    opacity: 0;
    width: 100%
  }
}

@keyframes progress-overlay {
  0% {
    opacity: .7;
    width: 0
  }
  to {
    opacity: 0;
    width: 100%
  }
}

.ch-breadcrumb-section {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis
}

.mobile-only {
  display: none !important
}

@media only screen and (max-width: 768px) {
  .mobile-only {
    display: block !important
  }
}

.computer-only {
  display: none !important
}

@media only screen and (min-width: 769px) {
  .computer-only {
    display: block !important
  }
}

.grow {
  flex-grow: 1
}

.row-link:hover {
  cursor: pointer
}

.ch-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .65);
  animation: fadeIn .3s
}

.ch-overlay.inside {
  top: 65px;
  background-color: rgba(0, 0, 0, .05)
}

.ch-overlay.inside .ch-sidebar-container {
  border-top: 1px solid #eee
}

@media only screen and (max-width: 768px) {
  .ch-overlay {
    background-color: #fff
  }

  .ch-overlay.inside {
    top: 0
  }
}

.hidden {
  display: none !important
}

.hidden.ui.divider {
  display: block !important
}

@media only screen and (max-width: 768px) {
  .hidden-mobile {
    display: none !important
  }
}

.close-dialog {
  cursor: pointer
}

.ch-modal .ui.header {
  font-size: auto;
  font-weight: 400;
  text-transform: uppercase;
  color: #606266
}

.fa-1_5x {
  font-size: 1.5em
}

.ch-expand-button {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: auto;
  align-self: flex-start;
  background-color: #252c38;
  z-index: 1
}

.ch-expand-button > button {
  width: 100%;
  height: 50px;
  font-family: inherit;
  padding: .7em 0 .7em 60px;
  text-align: left;
  font-weight: 600;
  color: #949fb2;
  background-color: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;
  outline: 0
}

.ch-expand-button > button:after {
  content: attr(data-minimize);
  opacity: .5;
  visibility: hidden;
  transition: all .1s ease-out
}

.ch-expand-button > button > .icon {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 20px;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ch-expand-button > button > .icon path {
  fill: #949fb2
}

.ch-expand-button > button > .icon.minimize {
  display: block
}

.ch-expand-button > button > .icon.expand, .ch-input-file input[type=file], .ch-nav.collapsed .ch-expand-button button > .icon.minimize, .ui.table.sortable thead th.sorted:after {
  display: none
}

.ch-expand-button > button:hover:after {
  opacity: 1;
  visibility: visible
}

.ch-nav.collapsed .ch-expand-button button:after {
  content: attr(data-expand)
}

.ch-nav.collapsed .ch-expand-button button > .icon.expand {
  display: block
}

@media only screen and (max-width: 768px) {
  .ch-expand-button {
    display: none
  }
}

.more button {
  outline: 0
}

.btn-more-options.ch-header-nav-button {
  order: 1;
  padding-right: 0;
  font-size: 1.25em
}

.info .description {
  color: #5f6266
}

.info .value {
  font-size: 1.15em
}

.info > .list > .value {
  line-height: 1.5em
}

@media only screen and (max-width: 768px) {
  .h-text-center--mobile {
    text-align: center !important
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #47494c;
  font-family: "Roboto", sans-serif
}

a {
  text-decoration: none
}

.ch-dropdown li, .ch-dropdown ul, li, ol, ul {
  list-style: none
}

:root [data-theme=billimatic], :root [data-theme=cobrato], :root [data-theme=default], :root [data-theme=emites], :root [data-theme=estoka], :root [data-theme=myfinance] {
  --main-color: $ mainColor
}

[data-theme=default] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label:before, [data-theme=default] .ch-menu-item.active a:before {
  border-right-color: #00e2f6
}

[data-theme=default] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label > .fa-icon:first-child, [data-theme=default] .ch-menu-item.active a > .fa-icon:first-child, [data-theme=default] .ch-menu-item.ch-dropdown.open > .ch-dropdown-button > .fa-icon:first-child {
  color: #00e2f6
}

[data-theme=default] .ch-pagination.ui.pagination.menu .item.active {
  border-bottom-color: #00e2f6
}

@media only screen and (max-width: 768px) {
  [data-theme=default] .ch-nav:not(.active) .ch-menu-item.active a:before {
    border-bottom-color: #00e2f6
  }
}

[data-theme=default] .ch-loader-progress:before {
  background-color: #00e2f6
}

[data-theme=billimatic] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label:before, [data-theme=billimatic] .ch-menu-item.active a:before {
  border-right-color: #f24242
}

[data-theme=billimatic] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label > .fa-icon:first-child, [data-theme=billimatic] .ch-menu-item.active a > .fa-icon:first-child, [data-theme=billimatic] .ch-menu-item.ch-dropdown.open > .ch-dropdown-button > .fa-icon:first-child {
  color: #f24242
}

[data-theme=billimatic] .ch-pagination.ui.pagination.menu .item.active {
  border-bottom-color: #f24242
}

@media only screen and (max-width: 768px) {
  [data-theme=billimatic] .ch-nav:not(.active) .ch-menu-item.active a:before {
    border-bottom-color: #f24242
  }
}

[data-theme=billimatic] .ch-loader-progress:before {
  background-color: #f24242
}

[data-theme=cobrato] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label:before, [data-theme=cobrato] .ch-menu-item.active a:before {
  border-right-color: #f89137
}

[data-theme=cobrato] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label > .fa-icon:first-child, [data-theme=cobrato] .ch-menu-item.active a > .fa-icon:first-child, [data-theme=cobrato] .ch-menu-item.ch-dropdown.open > .ch-dropdown-button > .fa-icon:first-child {
  color: #f89137
}

[data-theme=cobrato] .ch-pagination.ui.pagination.menu .item.active {
  border-bottom-color: #f89137
}

@media only screen and (max-width: 768px) {
  [data-theme=cobrato] .ch-nav:not(.active) .ch-menu-item.active a:before {
    border-bottom-color: #f89137
  }
}

[data-theme=cobrato] .ch-loader-progress:before {
  background-color: #f89137
}

[data-theme=emites] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label:before, [data-theme=emites] .ch-menu-item.active a:before {
  border-right-color: #13b782
}

[data-theme=emites] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label > .fa-icon:first-child, [data-theme=emites] .ch-menu-item.active a > .fa-icon:first-child, [data-theme=emites] .ch-menu-item.ch-dropdown.open > .ch-dropdown-button > .fa-icon:first-child {
  color: #13b782
}

[data-theme=emites] .ch-pagination.ui.pagination.menu .item.active {
  border-bottom-color: #13b782
}

@media only screen and (max-width: 768px) {
  [data-theme=emites] .ch-nav:not(.active) .ch-menu-item.active a:before {
    border-bottom-color: #13b782
  }
}

[data-theme=emites] .ch-loader-progress:before {
  background-color: #13b782
}

[data-theme=estoka] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label:before, [data-theme=estoka] .ch-menu-item.active a:before {
  border-right-color: #f2377a
}

[data-theme=estoka] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label > .fa-icon:first-child, [data-theme=estoka] .ch-menu-item.active a > .fa-icon:first-child, [data-theme=estoka] .ch-menu-item.ch-dropdown.open > .ch-dropdown-button > .fa-icon:first-child {
  color: #f2377a
}

[data-theme=estoka] .ch-pagination.ui.pagination.menu .item.active {
  border-bottom-color: #f2377a
}

@media only screen and (max-width: 768px) {
  [data-theme=estoka] .ch-nav:not(.active) .ch-menu-item.active a:before {
    border-bottom-color: #f2377a
  }
}

[data-theme=estoka] .ch-loader-progress:before {
  background-color: #f2377a
}

[data-theme=myfinance] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label:before, [data-theme=myfinance] .ch-menu-item.active a:before {
  border-right-color: #2483c4
}

[data-theme=myfinance] .ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label > .fa-icon:first-child, [data-theme=myfinance] .ch-menu-item.active a > .fa-icon:first-child, [data-theme=myfinance] .ch-menu-item.ch-dropdown.open > .ch-dropdown-button > .fa-icon:first-child {
  color: #2483c4
}

[data-theme=myfinance] .ch-pagination.ui.pagination.menu .item.active {
  border-bottom-color: #2483c4
}

@media only screen and (max-width: 768px) {
  [data-theme=myfinance] .ch-nav:not(.active) .ch-menu-item.active a:before {
    border-bottom-color: #2483c4
  }
}

[data-theme=myfinance] .ch-loader-progress:before {
  background-color: #2483c4
}

[data-staff] .ch-expand-button, [data-staff] .ch-nav, [data-staff] .ch-nav.active .ch-menu-item.more {
  background-color: #a40014
}

[data-staff] .ch-menu-item.active > a:before {
  border-right-color: #fff
}

[data-staff] .ch-menu-item.active > a > .fa-icon:first-child {
  color: #fff
}

[data-staff] .ch-expand-button > button, [data-staff] .ch-menu-item > a, [data-staff] .ch-menu-item > button {
  color: rgba(255, 255, 255, .7)
}

[data-staff] .ch-expand-button > button > .icon path {
  fill: rgba(255, 255, 255, .7)
}

[data-staff] .ch-nav:not(.active) .ch-menu-item.active a:before {
  border-bottom-color: #fff
}

[data-staff-label=true] .ch-logo-label:after {
  content: 'Staff';
  margin-left: .5em;
  font-size: .75rem;
  text-transform: uppercase;
  vertical-align: middle;
  color: rgba(255, 255, 255, .7)
}

.ui.button, .ui.header {
  font-family: "Roboto", sans-serif
}

.ui.form .field > label {
  font-weight: 400;
  color: #5f6b7f
}

.ui.form .fields {
  margin: 0 -.5em 1.5em
}

.ui.form .field {
  margin: 0 0 1.5em
}

.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url], .ui.form textarea {
  border-color: #e6eaf2
}

.ui.form .ui.checkbox label, .ui.form .ui.checkbox + label {
  color: #475266
}

.ui.form .ui.checkbox .box:before, .ui.form .ui.checkbox label:before {
  border-color: #d0d8e5
}

@media only screen and (max-width: 767px) {
  .ui.form .fields .field {
    margin: 0 0 1em
  }
}

.ui.icon.input > i.icon:not(.link) {
  color: #949fb2
}

.ui.grid > .row > .white.column, .ui.grid > .white.column, .ui.grid > .white.row {
  background-color: #fff
}

.ui.grid.padded.grid:not(.vertically):not(.horizontally) {
  padding: 10px
}

.ui.menu .item > i.fa-icon {
  float: none;
  margin: 0 .35714286em 0 0
}

@media only screen and (max-width: 480px) {
  .ui.menu.pagination .item {
    display: none
  }

  .ui.menu.pagination .item:first-child, .ui.menu.pagination .item:last-child {
    display: flex
  }
}

.ui.modal.ch-modal > .close {
  top: 1rem;
  right: .5rem;
  color: #47494c
}

.ui.modal > .header:not(.ui) {
  font-size: 1.2em;
  font-weight: 400;
  text-transform: uppercase;
  color: #606266
}

.ui.dimmer {
  background-color: rgba(0, 0, 0, .65)
}

.ui.segments {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .1) !important;
  border-color: #dbdbdb !important
}

.ui.segments > .segment {
  border-color: #dbdbdb;
  border-radius: 0 !important
}

.ui.segments > .segment:last-child {
  border-bottom: none
}

.ui.segments > .segment.borderless {
  border: 0;
  border-radius: 0;
  box-shadow: none
}

.ui.segments.horizontal {
  display: flex;
  align-items: center;
  margin: 0;
  border-radius: 0
}

.ui.segment {
  border-radius: 0;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, .1)
}

.ui.segment.grow {
  display: flex;
  flex-grow: 1;
  flex-direction: column
}

.ui.segment.borderless {
  border: 0;
  border-radius: 0;
  box-shadow: none
}

.ui.segment.space.between {
  display: flex;
  justify-content: space-between
}

.ui.segment.paddingless {
  padding: 0
}

.ui.segment.paddingless .ui.table td, .ui.segment.paddingless .ui.table th {
  padding-top: 1.2em;
  padding-bottom: 1.2em
}

.ui.segment.paddingless .ui.table td:first-child, .ui.segment.paddingless .ui.table th:first-child {
  padding-left: 1.6em
}

.ui.segment.paddingless .ui.table td:last-child, .ui.segment.paddingless .ui.table th:last-child {
  padding-right: 1.6em
}

.ui.segment.space.around {
  display: flex;
  align-items: center;
  flex-wrap: wrap
}

.ui.segment.space.around > .right.content {
  margin-left: auto
}

.ui.segment.space.around.separator {
  padding-bottom: 1em;
  border-bottom: 1px solid #ddd
}

@media only screen and (max-width: 768px) {
  .ui.segment.space.around.reverse.order .right.content {
    order: -1
  }
}

.ui.table.sortable thead th {
  color: #919499;
  border-left: none
}

.ui.table.sortable thead th a {
  color: #919499
}

.ui.table.sortable thead th.sorted a {
  color: rgba(0, 0, 0, .95)
}

.ui.table.sortable thead th.sorted, .ui.table.sortable thead th.sorted:hover, .ui.table.sortable thead th:hover {
  background: 0 0
}

.ui.table.sortable thead th.ascending a:after, .ui.table.sortable thead th.descending a:after {
  margin: 0 0 0 .5em;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300
}

.ui.table.sortable thead th.ascending a:after {
  content: "\f30c"
}

.ui.table.sortable thead th.descending a:after {
  content: "\f309"
}

.paddingless .ui.table {
  border: 0
}

.ui.table.expandable {
  background-color: #fff
}

.ui.table.selectable tbody tr.invisible:hover {
  background-color: transparent !important
}

.ui.table.selectable tbody tr.invisible .ch-details-row-content {
  position: relative
}

.ui.table[class*='very basic']:not(.sortable):not(.striped) thead tr:first-child th {
  padding-top: 1.2em
}

.ui.table .checkbox-column {
  width: 20px
}

@media only screen and (max-width: 768px) {
  .ui.table:not(.unstackable) thead {
    display: none
  }

  .ui.table:not(.unstackable) tr {
    display: flex !important;
    flex-direction: column
  }

  .ui.table:not(.unstackable) tr.ch-table-details.hidden {
    display: none !important
  }

  .ui.table:not(.unstackable) tr.ch-table-details .column {
    display: flex;
    justify-content: space-between;
    padding: .7em
  }

  .ui.table:not(.unstackable) tr > td {
    display: flex !important;
    justify-content: space-between;
    text-align: right
  }

  .ui.table:not(.unstackable) tr > td:before {
    content: attr(data-info);
    color: #47494c !important;
    font-weight: 600;
    text-align: left
  }

  .ui.table:not(.unstackable) tr > td.checkbox {
    justify-content: flex-start
  }

  .ui.table:not(.unstackable) tr > td.ch-table-buttons {
    justify-content: center
  }

  .ui.table:not(.unstackable) tr > td.ch-table-buttons:before {
    content: ''
  }

  .ui.table.actions:not(.unstackable) tr > td, .ui.table.actions:not(.unstackable) tr > th {
    padding: .5em 1em !important;
    text-align: left
  }

  .ui.table:not(.unstackable) {
    width: 100%;
    padding: 0
  }

  .ui.table:not(.unstackable) tbody, .ui.table:not(.unstackable) tr, .ui.table:not(.unstackable) tr > td, .ui.table:not(.unstackable) tr > th {
    width: auto !important
  }

  .ui.table:not(.unstackable) tfoot {
    display: block
  }

  .ui.table:not(.unstackable) tr {
    padding-top: 1em;
    padding-bottom: 1em;
    -webkit-box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .1) inset !important;
    box-shadow: 0 -1px 0 0 rgba(0, 0, 0, .1) inset !important
  }

  .ui.table:not(.unstackable) tr > td, .ui.table:not(.unstackable) tr > th {
    background: 0 0;
    border: 0 !important;
    padding: .25em .75em !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important
  }

  .ui.table:not(.unstackable) td:first-child, .ui.table:not(.unstackable) th:first-child {
    font-weight: 700
  }

  .ui.table.ui.definition:not(.unstackable) thead th:first-child {
    -webkit-box-shadow: none !important;
    box-shadow: none !important
  }
}

.ui.progress.top.legend {
  margin-top: 3em;
  margin-bottom: .75em
}

.ui.progress.top.legend .label {
  width: 100%;
  position: absolute;
  top: -35px;
  font-size: 1rem;
  font-weight: 400
}

.ui.dropdown.button.center.aligned {
  text-align: center
}

.ui.dropdown.button.center.aligned.fluid > .dropdown.icon {
  float: none
}

.ui.dropdown > .dropdown.icon {
  color: #7a8699;
  font-family: "Font Awesome 5 Pro"
}

.ui.dropdown > .dropdown.icon:before {
  content: "\f107"
}

.ui.dropdown.selection {
  border-color: #e6eaf2
}

i.icon.fa:before {
  content: inherit
}

i.icon {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal
}

i.icon.light {
  font-weight: 300 !important
}

i.icon.solid {
  font-weight: 900 !important
}

i.icon.regular {
  font-weight: 400 !important
}

.ui.divider.hidden.mini {
  margin: .25em
}

.ui.divider.hidden.small {
  margin: .5em
}

.ui.divider.hidden.large {
  margin: 1.5em
}

.ui.divider.hidden.huge {
  margin: 2.5em
}

.ch-logo {
  position: relative;
  height: 65px;
  min-height: 65px;
  padding-left: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.5em;
  color: #fff
}

.ch-logo.has-shadow {
  box-shadow: 0 1px 7px rgba(0, 0, 0, .2)
}

.ch-logo-label {
  white-space: nowrap
}

.ch-logo-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ch-logo-icon > img {
  max-width: 36px;
  max-height: 36px
}

@media only screen and (max-width: 768px) {
  .ch-logo {
    min-height: 72px;
    padding-left: 70px
  }

  .ch-logo-icon {
    left: 18px
  }

  .ch-nav:not(.active) .ch-logo {
    display: none
  }
}

.ch-menu {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden
}

@media only screen and (max-width: 768px) {
  .ch-nav:not(.active) .ch-menu {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around
  }
}

.ch-menu-item > a, .ch-menu-item > button {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 40px;
  line-height: 1.3em;
  padding: .7em 0 .7em 60px;
  font-weight: 600;
  color: #d0d8e5;
  background: 0 0;
  border: 0
}

.ch-menu-item > a > .fa-icon:first-child, .ch-menu-item > button > .fa-icon:first-child {
  position: absolute;
  top: 50%;
  left: 20px;
  width: 20px;
  height: 20px;
  font-size: 1.15em;
  text-align: center;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ch-menu-item > a-label, .ch-menu-item > a > .fa-icon:first-child, .ch-menu-item > button-label, .ch-menu-item > button > .fa-icon:first-child {
  line-height: 20px
}

.ch-nav:not(.collapsed) .ch-menu-item > a, .ch-nav:not(.collapsed) .ch-menu-item > button {
  padding-right: 1em
}

.ch-menu-item a:hover, .ch-menu-item.active > a {
  color: #fff
}

.ch-menu-item.active > a:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: auto;
  height: 20px;
  border-right: 5px solid #00e2f6;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ch-menu-item.active > a > .fa-icon:first-child {
  color: #00e2f6
}

.ch-menu-item.disabled > a {
  color: #949fb2;
  pointer-events: none
}

.ch-menu-item.ch-dropdown > .ch-dropdown-content {
  display: none;
  position: relative;
  top: auto;
  left: auto;
  margin-bottom: 20px;
  font-size: inherit;
  background-color: transparent;
  box-shadow: none
}

.ch-dropdown-content.bottom:before, .ch-menu-item.ch-dropdown > .ch-dropdown-content:before {
  content: none
}

.ch-menu-item.ch-dropdown > .ch-dropdown-button > .fa-icon.dropdown {
  position: absolute;
  margin-right: 1.2em;
  font-size: .65em;
  top: 50%;
  right: 0;
  height: auto;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transition: transform .3s;
  -o-transition: transform .3s;
  -webkit-transition: transform .3s;
  transition: transform .3s
}

.ch-menu-item.ch-dropdown > .ch-dropdown-button > .fa-icon.dropdown:before {
  content: "\f054"
}

.ch-nav.collapsed.hover .ch-menu-item.ch-dropdown.open.active > a:before, .ch-nav:not(.collapsed) .ch-menu-item.ch-dropdown.open.active > a:before {
  border-right-color: transparent
}

.ch-menu-item.ch-dropdown.open > .ch-dropdown-content {
  display: block
}

.ch-menu-item.ch-dropdown.open > .ch-dropdown-button > .fa-icon.dropdown {
  -moz-transform: translateY(-50%) rotate(90deg);
  -o-transform: translateY(-50%) rotate(90deg);
  -ms-transform: translateY(-50%) rotate(90deg);
  -webkit-transform: translateY(-50%) rotate(90deg);
  transform: translateY(-50%) rotate(90deg)
}

@media only screen and (max-width: 768px) {
  .ch-nav .ch-menu-item > a, .ch-nav .ch-menu-item > button {
    padding-top: 1.15em;
    padding-bottom: 1.15em;
    padding-left: 70px
  }

  .ch-nav .ch-menu-item > a > .fa-icon:first-child, .ch-nav .ch-menu-item > button > .fa-icon:first-child {
    font-size: 1.25em
  }

  .ch-nav:not(.active) .ch-menu-item {
    display: none;
    height: 100%
  }

  .ch-nav:not(.active) .ch-menu-item.more, .ch-nav:not(.active) .ch-menu-item:first-child, .ch-nav:not(.active) .ch-menu-item:nth-child(2), .ch-nav:not(.active) .ch-menu-item:nth-child(3), .ch-nav:not(.active) .ch-menu-item:nth-child(4) {
    display: block
  }

  .ch-nav:not(.active) .ch-menu-item-label, .ch-nav:not(.active) .ch-menu-item.ch-dropdown > .ch-dropdown-button > .fa-icon.dropdown, .ch-nav:not(.active) .ch-menu-item.ch-dropdown > .ch-dropdown-content {
    display: none
  }

  .ch-nav:not(.active) .ch-menu-item > a, .ch-nav:not(.active) .ch-menu-item > button {
    height: 100%;
    padding: 10px;
    justify-content: center
  }

  .ch-nav:not(.active) .ch-menu-item > a > .fa-icon:first-child, .ch-nav:not(.active) .ch-menu-item > button > .fa-icon:first-child {
    position: static;
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  .ch-nav:not(.active) .ch-menu-item.active a:before {
    width: 20px;
    height: auto;
    top: auto;
    bottom: 0;
    left: auto;
    right: 50%;
    border-right: none;
    border-bottom: 5px solid #00e2f6;
    -moz-transform: translate(50%, 0);
    -o-transform: translate(50%, 0);
    -ms-transform: translate(50%, 0);
    -webkit-transform: translate(50%, 0);
    transform: translate(50%, 0)
  }

  .ch-nav.active .ch-menu-item > a > .fa-icon:first-child, .ch-nav.active .ch-menu-item > button > .fa-icon:first-child {
    left: 25px
  }

  .ch-nav.active .ch-menu-item.more {
    position: fixed;
    bottom: 0;
    right: 0;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #252c38;
    z-index: 2
  }

  .ch-nav.active .ch-menu-item.more > button {
    width: 60px;
    height: 60px;
    padding-right: 0 !important
  }

  .ch-nav.active .ch-menu-item.more > button > .fa-icon {
    font-size: 1.5em
  }
}

.ch-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 1.25em;
  color: #47494c;
  overflow-x: hidden
}

.ch-breadcrumb-section {
  position: relative;
  padding: 5px;
  font-weight: 300;
  color: inherit;
  border-radius: 3px
}

.ch-breadcrumb-section:hover {
  color: #47494c;
  background-color: #f9fbff
}

.ch-breadcrumb-section a {
  color: currentColor
}

.ch-breadcrumb-section.active {
  font-weight: 600
}

.ch-breadcrumb .divider {
  opacity: .5
}

@media only screen and (max-width: 768px) {
  .ch-breadcrumb .divider, .ch-breadcrumb-section {
    display: none;
    white-space: nowrap
  }

  .ch-breadcrumb-section.active {
    display: block
  }

  .ch-breadcrumb .dropdown-toggle {
    pointer-events: none
  }
}

.ch-dropdown {
  position: relative
}

.ch-dropdown-content {
  display: none;
  position: absolute;
  top: 0;
  left: 84px;
  min-width: 200px;
  font-weight: 400;
  color: #47494c;
  background-color: #fff;
  box-shadow: 0 4px 30px rgba(0, 0, 0, .2);
  border-radius: 3px;
  outline: 0;
  z-index: 1;
  animation: fadeIn .3s
}

.ch-dropdown-content:before {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  background-color: #fff;
  position: absolute;
  top: 25px;
  left: -5px;
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: -2px 2px 2px rgba(0, 0, 0, .15);
  z-index: -1
}

.ch-dropdown-content.visible {
  display: block
}

.ch-dropdown-content.bottom {
  top: calc(100% + .625em)
}

.ch-dropdown-content.bottom.right {
  left: auto;
  right: 0
}

.ch-dropdown-content.bottom.left {
  right: auto;
  left: 0
}

.ch-dropdown-header {
  display: block;
  padding: 10px 25px;
  font-size: 1em;
  color: #777a7f;
  cursor: default
}

.ch-dropdown-menu-item a {
  display: block;
  padding: 10px 25px;
  font-size: 1em;
  color: #47494c
}

.ch-dropdown-menu-item {
  position: relative
}

.ch-dropdown-menu-item:first-child {
  border-top: 0
}

.ch-dropdown-menu-item.active {
  font-weight: 700
}

.ch-dropdown-menu-item:hover .ui.image.avatar ~ .ui.button {
  visibility: visible;
  opacity: 1
}

.ch-dropdown-menu-item:hover a, .ch-header-nav-button.ch-account.active button {
  color: #777a7f
}

.ch-dialog .ui.items > .item > .content .header > .symbol, .ch-dropdown-menu-item .ui.image.avatar {
  margin-right: 10px
}

.ch-dropdown-menu-item .ui.image.avatar ~ .ui.button {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-left: auto;
  visibility: hidden;
  opacity: 0;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -moz-transition: all .2s;
  -o-transition: all .2s;
  -webkit-transition: all .2s;
  transition: all .2s
}

@media only screen and (max-width: 768px) {
  .ch-dropdown-content.collapse {
    position: static;
    box-shadow: none;
    z-index: 1
  }

  .ch-dropdown-content.collapse .ch-dropdown-header {
    display: none
  }

  .ch-dropdown-header {
    border-bottom: 1px solid rgba(255, 255, 255, .2)
  }
}

.dropdown-list .ui.dropdown + .ui.dropdown {
  margin-left: .5em
}

@media only screen and (max-width: 768px) {
  .dropdown-list .ui.dropdown {
    width: 100%;
    margin-bottom: 1em
  }

  .dropdown-list .ui.dropdown + .ui.dropdown {
    margin-left: 0
  }
}

.ch-dialog {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999
}

.ch-dialog-container, .ch-dialog-container.active {
  -moz-transition: all .3s;
  -o-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ch-dialog-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  min-height: 100%;
  margin-left: auto;
  padding: 20px;
  background-color: #fff;
  overflow-y: auto;
  -moz-transform: translate(100%);
  -o-transform: translate(100%);
  -ms-transform: translate(100%);
  -webkit-transform: translate(100%);
  transform: translate(100%)
}

.ch-dialog-container.active {
  -moz-transform: translate(0);
  -o-transform: translate(0);
  -ms-transform: translate(0);
  -webkit-transform: translate(0);
  transform: translate(0)
}

.ch-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 65px;
  padding-bottom: 15px;
  color: #606266;
  border-bottom: 1px solid #edeff2
}

.ch-avatar-upload .info p, .ch-dialog-header .icon {
  margin: 0
}

.ch-dialog-content {
  padding-bottom: 50px;
  overflow: hidden
}

.inside .ch-dialog-content {
  padding-bottom: 120px
}

.ch-dialog-message {
  justify-content: center;
  opacity: .4
}

.ch-dialog .ui.items {
  margin-top: 0
}

.ch-dialog .ui.items > .item {
  margin: 0;
  flex-direction: row;
  border: 0
}

.ch-dialog .ui.items > .item:first-child {
  padding-top: 1em !important
}

.ch-dialog .ui.items > .item.unread {
  opacity: 1
}

.ch-dialog .ui.items > .item.new {
  position: relative
}

.ch-dialog .ui.items > .item.new:after {
  content: "";
  position: absolute;
  top: 15px;
  right: 0;
  display: block;
  width: 10px;
  height: 10px;
  background-color: #00cae5;
  visibility: visible;
  border-radius: 100%
}

.ch-dialog .ui.items > .item > .image {
  padding: 10px 0 10px 20px
}

.ch-dialog .ui.items > .item > .image img {
  border-radius: 100%
}

.ch-dialog .ui.items > .item > .content .description, .ch-dialog .ui.items > .item > .content .header {
  color: inherit
}

.ch-dialog .ui.items > .item > .content .header {
  font-size: 1em
}

.ch-dialog .ui.items > .item > .content .description {
  margin: 5px 0
}

.ch-dialog .ui.items > .item > .content .time {
  text-transform: uppercase;
  color: #606266
}

@media only screen and (max-width: 768px) {
  .ch-dialog-container {
    width: 100%;
    animation: fadeIn .3s
  }

  .ch-dialog .ui.items:not(.unstackable) > .item > .image + .content {
    padding: 10px
  }
}

.action-dialog, .action-dialog.active {
  -moz-transition: all .3s;
  -o-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s
}

.action-dialog {
  cursor: pointer;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 400px;
  padding: 15px;
  font-size: 14px;
  text-align: center;
  text-transform: uppercase;
  color: #47494c;
  border-top: 1px solid #edeff2;
  background-color: #fff;
  -moz-transform: translate(100%);
  -o-transform: translate(100%);
  -ms-transform: translate(100%);
  -webkit-transform: translate(100%);
  transform: translate(100%)
}

.action-dialog.active {
  -moz-transform: translate(0);
  -o-transform: translate(0);
  -ms-transform: translate(0);
  -webkit-transform: translate(0);
  transform: translate(0)
}

@media only screen and (max-width: 768px) {
  .action-dialog {
    max-width: 100%
  }
}

.btn-close-dialog {
  color: inherit;
  background: 0 0;
  border: 0;
  outline: 0
}

.ch-search {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999
}

.ch-search-content {
  margin-left: 240px;
  width: calc(100% - 240px);
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .35)
}

.ch-nav.collapsed ~ .ch-search .ch-search-content {
  margin-left: 60px;
  width: calc(100% - 60px)
}

.ch-search-input-group {
  display: flex;
  align-items: center;
  height: 65px;
  background-color: #fff;
  animation: fadeIn .1s
}

.ch-search-form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 15px 5px 15px 15px
}

.ch-search-form-input {
  flex-grow: 1;
  font-family: inherit;
  font-size: 1.2em;
  color: #47494c;
  background-color: #fff;
  border: 0
}

.ch-search-form-input::placeholder {
  opacity: .5
}

.ch-search-form-input:focus {
  outline: 0
}

.ch-search-form-icon {
  margin-left: 5px;
  margin-right: 10px;
  color: #46494c;
  order: -1;
  border: 0;
  background: 0 0;
  outline: 0
}

.ch-search-form-icon:active, .ch-search-form-icon:hover {
  color: #46494c
}

.ch-search-form-icon.btn-close {
  order: 1
}

.ch-search-form:after, .ch-search-form:before {
  color: #d0d8e5
}

.ch-search-form:before {
  content: attr(data-message);
  order: 1;
  margin-right: .625em
}

.ch-search-form:after {
  content: 'ESC';
  margin: 0 5px;
  padding: 0 4px;
  font-weight: 600;
  background: #f9fbff;
  border: 1px solid rgba(148, 159, 178, .5);
  border-radius: 3px
}

@media only screen and (max-width: 768px) {
  .ch-search-content {
    margin: 0 !important;
    width: 100% !important;
    height: 100%;
    flex-wrap: wrap;
    box-shadow: none
  }

  .ch-search-context {
    display: none
  }

  .ch-search-form:after, .ch-search-form:before {
    content: none
  }

  .ch-search-input-group {
    border-bottom: 1px solid #edeff2
  }
}

.ch-search-results {
  display: none;
  overflow: auto;
  max-height: calc(100% - 65px);
  background-color: #fff;
  border-top: 1px solid #edeff2;
  border-radius: 0 0 3px 3px
}

.ch-search-results-count, .ch-search-results-label {
  display: block;
  font-size: 1em;
  color: #949fb2
}

.ch-search-results-count {
  padding: 24px 24px 0
}

.ch-search-results-show-more {
  color: #949fb2;
  font-weight: 600
}

.ch-search-results-item {
  display: block;
  padding: 10px 15px;
  color: #47494c;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ch-search-results-item:hover {
  cursor: pointer;
  color: inherit;
  background-color: #color navy, tone-5
}

.ch-table-buttons .ui.icon.button {
  padding: .5em;
  background-color: transparent
}

.ch-table-label .ui.label {
  width: 100%;
  max-width: 175px;
  text-align: center
}

.ch-table-details-title {
  font-size: auto;
  font-weight: 400;
  text-transform: uppercase;
  color: #606266
}

.ch-table.ui.table td.positive {
  color: #16ab39 !important;
  background: 0 0 !important
}

.ch-table.ui.table td.negative {
  color: #db2828 !important;
  background: 0 0 !important
}

.bind-popup + .ui.popup {
  max-width: 275px
}

.bind-popup + .ui.popup .ui.label {
  margin: .25em;
  white-space: nowrap
}

.ch-wizard--horizontal > .ch-wizard-step a:before, .ch-wizard--vertical > .ch-wizard-step > .header:before {
  counter-increment: steps;
  content: counter(steps);
  min-width: 35px;
  height: 35px;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2em;
  font-weight: 800;
  color: #d6d6d6;
  background-color: #fff;
  border: 2px solid #d6d6d6;
  border-radius: 100%
}

.ch-wizard--horizontal > .ch-wizard-step a:before {
  margin-right: 0;
  margin-bottom: 5px
}

.ch-wizard--horizontal > .ch-wizard-step.done a:before, .ch-wizard--vertical > .ch-wizard-step.done > .header:before {
  content: "\f00c";
  font-family: "Icons";
  color: #fff;
  background-color: #2b4;
  border-color: #2b4
}

.ch-wizard--horizontal > .ch-wizard-step.current a:before, .ch-wizard--vertical > .ch-wizard-step.current > .header:before {
  color: #fff;
  background-color: #838383;
  border-color: #838383
}

.ch-wizard {
  counter-reset: steps
}

.ch-wizard-container {
  position: relative;
  z-index: 0
}

.ch-wizard--vertical > .ch-wizard-step {
  opacity: .3
}

.ch-wizard--vertical > .ch-wizard-step > .header {
  position: relative;
  display: flex
}

.ch-wizard--vertical > .ch-wizard-step > .content {
  padding: 2em;
  margin: .5em 1.2em;
  visibility: hidden
}

.ch-wizard--vertical > .ch-wizard-step.current, .ch-wizard--vertical > .ch-wizard-step.done {
  opacity: 1
}

.ch-wizard--vertical > .ch-wizard-step.current > .content, .ch-wizard--vertical > .ch-wizard-step.done > .content {
  border-left: 2px dashed #d6d6d6;
  visibility: visible
}

.ch-wizard--vertical > .ch-wizard-step.done {
  color: #2b4
}

@media only screen and (max-width: 768px) {
  .ch-wizard--vertical > .ch-wizard-step.current > .step-content, .ch-wizard--vertical > .ch-wizard-step.done > .step-content {
    border: 0
  }
}

.ch-wizard--horizontal {
  display: flex;
  align-items: flex-start
}

.ch-wizard--horizontal > .ch-wizard-step {
  position: relative;
  margin: 5px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  line-height: normal;
  opacity: .5
}

.ch-wizard--horizontal > .ch-wizard-step a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: inherit
}

.ch-wizard--horizontal > .ch-wizard-step:after {
  content: "";
  position: absolute;
  top: 17px;
  left: 50%;
  width: calc(100% + 25px);
  height: 2px;
  background-color: #d6d6d6;
  z-index: -1
}

.ch-wizard--horizontal > .ch-wizard-step:last-child {
  margin-right: 0
}

.ch-wizard--horizontal > .ch-wizard-step:last-child:after {
  content: none
}

.ch-wizard--horizontal > .ch-wizard-step.current a {
  color: #47494c
}

.ch-wizard--horizontal > .ch-wizard-step.done a {
  color: #2b4
}

@media only screen and (min-width: 769px) and (max-width: 1366px) {
  .ch-wizard--horizontal {
    width: 100%;
    margin-top: 30px
  }
}

.ch-wizard-step.current, .ch-wizard-step.done {
  opacity: 1
}

.ch-wizard-step .options {
  min-width: 120px;
  padding: .6rem 1rem;
  background-color: #e8e8e8;
  border: 1px solid #ccc;
  border-radius: 5px
}

.ch-wizard-step .options.checked {
  background-color: #fff;
  border-color: #ccc
}

.ch-segment-header {
  display: flex;
  justify-content: space-between
}

.ch-segment-header.center.aligned {
  justify-content: center
}

.ch-segment-header-title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  color: #606266
}

.ch-segment-header-button {
  color: #47494c;
  font-size: 12px;
  text-transform: uppercase
}

.ch-segment-header-button:hover {
  color: #787c81
}

.ch-segment-footer-buttons {
  display: flex;
  justify-content: space-between
}

.noUi-target, .noUi-target * {
  touch-action: none;
  user-select: none;
  box-sizing: border-box
}

.noUi-target {
  position: relative;
  direction: ltr
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0
}

.noUi-handle {
  position: relative;
  z-index: 1;
  border: 0;
  box-shadow: none
}

.noUi-handle:after, .noUi-handle:before {
  content: none
}

.noUi-stacking .noUi-handle {
  z-index: 10
}

.noUi-state-tap .noUi-origin {
  transition: left .3s, top .3s
}

.noUi-state-drag * {
  cursor: inherit !important
}

.noUi-base, .noUi-handle {
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.noUi-horizontal {
  height: 4px
}

.noUi-horizontal .noUi-handle {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  left: -7px;
  top: -7px;
  background-color: var(--main-color-dark)
}

.noUi-background {
  background: #d6d7d9
}

.noUi-connect {
  background: var(--main-color-dark);
  -moz-transition: background 450ms;
  -o-transition: background 450ms;
  -webkit-transition: background 450ms;
  transition: background 450ms
}

.noUi-origin, .noUi-target {
  border-radius: 2px
}

.noUi-target {
  background-color: #e4e4e4;
  border: 0;
  box-shadow: none
}

.noUi-draggable {
  cursor: w-resize
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important
}

.noUi-handle:active, .noUi-handle:focus {
  outline: 0
}

.noUi-handle:active {
  border: 8px solid var(--main-color-dark);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -14px;
  top: -14px
}

[disabled] .noUi-connect, [disabled].noUi-connect {
  background: #b8b8b8
}

[disabled] .noUi-handle, [disabled].noUi-origin {
  cursor: not-allowed
}

.slider-container {
  margin: 0 5px
}

.ch-tabs {
  width: 100%;
  overflow: hidden
}

.ch-tabs > .ui.secondary.pointing.menu {
  border-bottom-color: transparent
}

@media only screen and (max-width: 768px) {
  .ch-tabs {
    position: relative;
    height: 40px;
    overflow: hidden
  }

  .ch-tabs .ui.menu {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding-bottom: 10px;
    overflow-x: scroll
  }
}

.ch-tag--new.ui.label {
  margin-left: 5px;
  padding: .3em .55em;
  color: #252c38;
  background-color: #ffa719;
  border: 2px solid #252c38;
  border-radius: 500em;
  text-transform: uppercase
}

.ch-tag--new:before {
  content: attr(data-label)
}

.ch-nav.collapsed:not(.hover) .ch-tag--new.ui.label {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  margin-left: 0;
  font-size: 0;
  border-radius: 100%;
  transform: translate(0, -75%)
}

.ch-nav.collapsed:not(.hover) .ch-tag--new.ui.label:before {
  content: ''
}

@media only screen and (max-width: 768px) {
  .ch-nav:not(.active) .ch-tag--new.ui.label {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    font-size: 0;
    border-radius: 100%;
    transform: translate(0, -75%)
  }

  .ch-nav:not(.active) .ch-tag--new.ui.label:before {
    content: ''
  }

  .ch-nav.active .ch-tag--new.ui.label {
    font-size: .75em
  }
}

.ch-timeline-container > .info > .header {
  width: 100%;
  display: flex;
  align-items: center
}

.ch-timeline-container > .info > .header .symbol {
  width: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  margin-right: .75em
}

[data-theme=emites] .ch-timeline-container > .info > .header .symbol {
  color: #089f69
}

.ch-timeline-container > .info > .header .date {
  margin-left: auto
}

.ch-timeline-container > .info > .header .date .text-uppercase {
  text-transform: uppercase
}

.ch-timeline-container > .info > .content {
  padding: 1em 1.5em;
  margin-left: .75em;
  border-left: 1px solid #ddd
}

[data-theme=emites] .ch-timeline-container > .info > .content {
  border-left-color: rgba(8, 159, 105, .25)
}

.ch-timeline-container > .info:last-child > .content {
  border: 0
}

.ch-notification {
  display: flex;
  align-items: center;
  min-width: 350px;
  max-width: 450px;
  margin: 20px 0;
  padding: 25px;
  background: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, .15)
}

.ch-notification-container {
  position: fixed;
  z-index: 2
}

.ch-notification-container.top {
  top: 85px
}

.ch-notification-container.right {
  right: 25px
}

.ch-notification-container.center {
  left: 50%;
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.ch-notification-container.left {
  left: calc(85px + 25px)
}

.ch-notification-container.bottom {
  bottom: 35px
}

.ch-notification-container .ui.icon.message > .icon:not(.close) {
  font-size: 2em
}

@media only screen and (max-width: 768px) {
  .ch-notification-container {
    width: 90%;
    top: 65px !important;
    left: 50% !important;
    right: auto !important;
    bottom: auto !important;
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%)
  }

  .ch-notification-container .ui.icon.message {
    min-width: 100%;
    max-width: 100%
  }
}

.ch-notification-icon {
  margin-right: 20px
}

.ch-notification-title {
  display: block;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #606266;
  margin-bottom: 5px
}

.ch-notification:first-child {
  margin-top: 0
}

.ch-notification--default .ch-notification-title, .ch-notification--default .circular.icon {
  color: #666
}

.ch-notification--default .circular.icon {
  box-shadow: 0 0 0 .1em #666 inset
}

.ch-notification--success .ch-notification-title, .ch-notification--success .circular.icon {
  color: #2b4
}

.ch-notification--success .circular.icon {
  box-shadow: 0 0 0 .1em #2b4 inset
}

.ch-notification--danger .ch-notification-title, .ch-notification--danger .circular.icon {
  color: #db2828
}

.ch-notification--danger .circular.icon {
  box-shadow: 0 0 0 .1em #db2828 inset
}

.ch-notification--info .ch-notification-title, .ch-notification--info .circular.icon {
  color: #2185d0
}

.ch-notification--info .circular.icon {
  box-shadow: 0 0 0 .1em #2185d0 inset
}

.ch-notification--warning .ch-notification-title, .ch-notification--warning .circular.icon {
  color: #fbbd08
}

.ch-notification--warning .circular.icon {
  box-shadow: 0 0 0 .1em #fbbd08 inset
}

.ch-notification-button--close {
  margin-left: auto;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer
}

.ch-notification-button--close .icon.close {
  color: #d6d6d6
}

@media only screen and (max-width: 768px) {
  .ch-notification {
    min-width: 100%;
    max-width: 100%;
    padding: 20px
  }
}

.ch-pagination.ui.pagination.menu {
  font-family: inherit;
  background: 0 0;
  border: 0;
  border-radius: 0;
  box-shadow: none
}

.ch-pagination.ui.pagination.menu .item {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 1em;
  margin-left: .75em;
  margin-right: .75em;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: .25em;
  color: #919499;
  font-weight: 600
}

.ch-pagination.ui.pagination.menu .item.active {
  border-bottom: 2px solid #ccc;
  color: #47494c;
  background: 0 0
}

.ch-pagination.ui.pagination.menu .item:hover {
  color: #47494c;
  background: 0 0
}

.ch-account-content-submenu .ch-dropdown-menu-item.open > .ch-dropdown-menu-item-label:before, .ch-pagination.ui.pagination.menu .item:before {
  content: none
}

.ch-message, .ch-message.ch-message--hide {
  -o-animation-duration: .65s;
  -moz-animation-duration: .65s;
  -webkit-animation-duration: .65s;
  animation-duration: .65s;
  -o-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}

.ch-message {
  position: fixed;
  display: flex;
  align-items: center;
  right: 2.375em;
  bottom: 2.375em;
  z-index: 10;
  background: #232323;
  padding: 1.25em 3.25em 1.25em 1.5em;
  min-width: 28.125em;
  max-width: 37.5em;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -o-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp
}

.ch-message.ch-message--hide {
  -o-animation-name: bounceOutDown;
  -moz-animation-name: bounceOutDown;
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown
}

.ch-message-text {
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  font-variant: normal;
  margin-bottom: auto;
  padding: 0;
  color: #fff
}

.ch-message-icon {
  font-weight: 700;
  margin-right: .5em;
  font-size: 1.25em;
  float: left
}

.ch-message-icon--info {
  color: #54c8ff
}

.ch-message-icon--success {
  color: #2ecc40
}

.ch-message-icon--error {
  color: #ff695e
}

.ch-message-close {
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  color: #ccc;
  font-size: 1.25em;
  text-align: right;
  display: block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  box-shadow: none
}

@media only screen and (max-width: 768px) {
  .ch-message {
    min-width: 100%;
    margin-bottom: 3em;
    right: 0
  }

  .ch-message-close, .ch-message-icon, .ch-message-text {
    font-size: .938em
  }
}

.ch-inline-search.ui.input > .ch-inline-search-input {
  border-radius: 500em;
  border: 1px solid #e6eaf2;
  border-right: none
}

.ch-inline-search.ui.input > .ui.icon.button.basic.ch-inline-search-button {
  border: 1px solid #e6eaf2;
  border-left: none;
  border-radius: 0 500em 500em 0;
  box-shadow: none
}

.ch-inline-search.ui.input > .ch-inline-search-input:active, .ch-inline-search.ui.input > .ui.icon.button.basic.ch-inline-search-button:active {
  background: 0 0 !important
}

.ch-inline-search.ui.input > .ch-inline-search-input:focus, .ch-inline-search.ui.input > .ch-inline-search-input:focus + .ui.icon.button.basic {
  border-color: #85b7d9
}

.ch-loader-progress {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: 0 0;
  z-index: 2
}

.ch-loader-progress.is-active {
  display: block
}

.ch-loader-progress:after, .ch-loader-progress:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 5px;
  opacity: 0
}

.ch-loader-progress:before {
  -o-animation: progress-active 2s ease infinite;
  -moz-animation: progress-active 2s ease infinite;
  -webkit-animation: progress-active 2s ease infinite;
  animation: progress-active 2s ease infinite
}

.ch-loader-progress:after {
  background: #fff;
  -o-animation: progress-overlay 2s ease infinite;
  -moz-animation: progress-overlay 2s ease infinite;
  -webkit-animation: progress-overlay 2s ease infinite;
  animation: progress-overlay 2s ease infinite
}

.ch-content {
  margin-left: 240px;
  transition: all .3s
}

.ch-content .ch-header {
  position: static;
  width: 100%;
  background: 0 0;
  box-shadow: none
}

@media only screen and (max-width: 768px) {
  .ch-content {
    width: 100%;
    margin: 0;
    padding-bottom: 60px
  }
}

.ch-avatar-container {
  width: 35px
}

.ch-avatar-container.big {
  width: 105px
}

.ch-avatar, .ch-avatar > .ui.image.avatar {
  width: 36px;
  height: 36px
}

.big .ch-avatar {
  width: 105px;
  height: 105px
}

.ch-avatar.rounded {
  border-radius: 100%
}

.ch-avatar, .ch-avatar-upload {
  display: flex;
  align-items: center
}

.ch-avatar-upload .info {
  margin: 0 1.5em
}

.ch-avatar-upload .info .ui.header {
  margin-bottom: 5px
}

.ch-avatar-upload .info .ui.button {
  margin: 10px 0 0
}

.ch-avatar-upload.vertical {
  display: block;
  text-align: center
}

.ch-avatar-upload.vertical .ch-avatar-container {
  margin: 0 auto
}

.ch-detail-header .info {
  display: flex;
  vertical-align: middle
}

.ch-detail-header .info .ui.header {
  margin-top: 0;
  margin-left: 20px;
  align-self: center
}

.ch-featured-content {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 65px)
}

.ch-featured-content .ui.grid.padded.grid:not(.vertically):not(.horizontally) > .column.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: auto;
  background-color: #fbfafa
}

@media only screen and (max-width: 768px) {
  .ch-featured-content .ui.grid.padded.grid:not(.vertically):not(.horizontally) > .column.sidebar {
    position: static;
    width: calc(100% + 20px) !important;
    margin-left: -.7143em !important;
    margin-right: -.7143em !important;
    height: auto;
    border-left: 0
  }

  .ch-featured-content .ui.grid.padded.grid:not(.vertically):not(.horizontally) > .column.sidebar.reverse-order {
    order: -1;
    margin-bottom: 2em !important
  }
}

.ch-header, .ch-header-nav {
  display: flex;
  align-items: center
}

.ch-header {
  padding: 20px;
  height: 65px;
  min-height: 65px;
  background-color: #fff;
  justify-content: space-between
}

.ch-header.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, .95);
  box-shadow: 0 -5px 20px rgba(0, 0, 0, .15);
  z-index: 1
}

.ch-header-nav {
  justify-content: center;
  margin-left: auto;
  color: #5f6266
}

.ch-header-nav-button {
  display: block;
  margin-right: 10px;
  margin-left: 10px;
  color: currentColor;
  transition: all .3s;
  background: 0 0;
  border: 0;
  outline: 0;
  cursor: pointer
}

.ch-header-nav-button button {
  font-family: "Roboto", sans-serif;
  border: 0;
  background: 0 0;
  outline: 0;
  cursor: pointer
}

.ch-header-nav-button.active, .ch-header-nav-button:hover {
  color: #919499;
  border-radius: 3px
}

.ch-header-nav-button:last-child {
  padding-right: 0
}

.ch-header-nav-button.ch-account > button {
  font-weight: 700;
  text-transform: uppercase;
  color: #47494c
}

.ch-header-nav-button.ch-account .fa-icon.dropdown {
  margin-left: .25rem;
  font-size: .65em
}

@media only screen and (max-width: 768px) {
  .ch-header-nav.active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 65px;
    padding: 20px;
    justify-content: space-between;
    background-color: #fff
  }

  .ch-header-nav-button {
    display: none
  }

  .ch-header-nav-button:first-child, .ch-header-nav-button:nth-child(2), .ch-header-nav-button:nth-child(3) {
    display: block
  }

  .ch-header-nav.active .ch-header-nav-button {
    display: block
  }
}

.ch-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #252c38;
  z-index: 3;
  overflow-x: hidden
}

.ch-nav.collapsed {
  width: 60px;
  -moz-transition: all .2s;
  -o-transition: all .2s;
  -webkit-transition: all .2s;
  transition: all .2s
}

.ch-nav.collapsed + .ch-content {
  margin-left: 60px
}

.ch-nav.collapsed .ch-collapse-button-label, .ch-nav.collapsed .ch-logo-label, .ch-nav.collapsed .ch-menu-item-label, .ch-nav.collapsed .ch-menu-item.ch-dropdown.open > .ch-dropdown-content {
  display: none
}

.ch-nav.collapsed .ch-menu-item-label {
  white-space: nowrap
}

.ch-nav.collapsed.hover {
  width: 240px;
  box-shadow: 0 0 25px rgba(0, 0, 0, .54)
}

.ch-nav.collapsed.hover .ch-collapse-button-label, .ch-nav.collapsed.hover .ch-logo-label, .ch-nav.collapsed.hover .ch-menu-item-label, .ch-nav.collapsed.hover .ch-menu-item.ch-dropdown.open > .ch-dropdown-content {
  display: block
}

.ch-nav.collapsed.hover .ch-logo-label, .ch-nav.collapsed.hover .ch-menu-item-label {
  white-space: normal;
  animation: fadeIn .75s
}

.ch-nav.collapsed.hover .ch-logo-icon, .ch-nav.collapsed.hover .ch-menu-item.active > a:before, .ch-nav.collapsed.hover .ch-menu-item > a > .fa-icon:first-child {
  animation: fadeIn 1s
}

@media only screen and (max-width: 768px) {
  .ch-nav {
    font-size: 1.2em
  }

  .ch-nav:not(.active) {
    width: 100% !important;
    height: 60px;
    top: auto;
    bottom: 0
  }

  .ch-nav:not(.active).collapsed.hover .ch-collapse-button-label, .ch-nav:not(.active).collapsed.hover .ch-logo-label, .ch-nav:not(.active).collapsed.hover .ch-menu-item-label, .ch-nav:not(.active).collapsed.hover .ch-menu-item.ch-dropdown.open > .ch-dropdown-content {
    display: none
  }

  .ch-nav.active {
    width: 100% !important
  }

  .ch-nav.collapsed, .ch-nav.collapsed.hover {
    box-shadow: none;
    -moz-transition: none;
    -o-transition: none;
    -webkit-transition: none;
    transition: none
  }

  .ch-nav.collapsed + .ch-content {
    margin-left: 0
  }
}

.ch-login, .ch-login-container {
  position: relative;
  width: 100%;
  height: 100%
}

.ch-login:after, .ch-login:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.ch-login:before {
  background-image: url(../images/login_cover.png);
  background-size: cover;
  background-position: center bottom;
  filter: grayscale(100%) contrast(1.2);
  z-index: -1;

}

.ch-login:after {
  background-color: #252c38;
  opacity: .85;
  z-index: 0
}

.ch-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 60px;
  width: 50%;
  background-color: #fff;
  z-index: 1
}

.ch-login-header {
  width: 100%;
  max-width: 330px;
  margin-left: auto;
  margin-right: auto;
  text-align: left
}

.ch-login-header .ui.header:not(.sub) {
  color: #47494c;
  font-weight: 500;
  font-size: 1.5em
}

.ch-login-header .ui.sub.header {
  text-transform: none;
  font-size: 1rem !important;
  margin-top: .25rem;
  color: rgba(71, 73, 76, .7)
}

.ch-login-header .logo-nexaas {
  width: auto;
  height: 1em;
  display: block
}

.ch-login-header .text {
  display: block;
  font-weight: 300;
  font-size: 1.3em
}

.ch-login-form {
  max-width: 330px;
  padding: 20px 0 0px;
  margin-left: auto;
  margin-right: auto
}

.ch-login-form .ui.header {
  font-weight: 300;
  font-size: 1.5em;
  text-align: center
}

.ch-login-form .button {
  color: #fff;
  background-color: #252c38
}

.ch-login-form .button:hover {
  color: #fff;
  background-color: #2f3847
}

.ch-login-helpers {
  padding: 30px 0;
  color: #999
}

.ch-login-helpers span {
  display: block;
  margin-bottom: 15px;
  text-align: center
}

.ch-login-helpers a {
  color: inherit;
  text-decoration: underline
}

.ch-login-footer {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  padding: 20px 60px;
  text-align: center
}

.ch-login-footer li, .ch-login-helpers a {
  display: inline-block
}

.ch-login-footer li a {
  color: #999;
  display: block;
  padding: 10px
}

@media only screen and (max-width: 768px) {
  .ch-login-container {
    width: 100%;
    padding: 20px
  }

  .ch-login-footer {
    padding: 20px
  }
}

.ch-sidebar--fixed {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 320px;
  min-height: 100%;
  margin-left: auto;
  padding: 25px;
  padding-top: calc(65px + 30px);
  background-color: #fff;
  border-left: 1px solid #ddd;
  overflow-y: auto;
  z-index: 0;
  -moz-transition: all .3s;
  -o-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ch-sidebar--fixed.inactive {
  -moz-transform: translateX(320px);
  -o-transform: translateX(320px);
  -ms-transform: translateX(320px);
  -webkit-transform: translateX(320px);
  transform: translateX(320px)
}

@media only screen and (max-width: 768px) {
  .ch-sidebar--fixed {
    width: 100%;
    top: 50%;
    min-height: 50%;
    padding-top: 20px;
    border: 0
  }
}

.ch-sidebar-button {
  position: fixed;
  top: 50%;
  right: 0;
  -moz-transform: translate(-312px, -50%);
  -o-transform: translate(-312px, -50%);
  -ms-transform: translate(-312px, -50%);
  -webkit-transform: translate(-312px, -50%);
  transform: translate(-312px, -50%)
}

.ch-sidebar-button.inactive {
  -moz-transform: translate(2px, -50%);
  -o-transform: translate(2px, -50%);
  -ms-transform: translate(2px, -50%);
  -webkit-transform: translate(2px, -50%);
  transform: translate(2px, -50%)
}

.ch-sidebar-button.ui.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 20px 5px;
  background-color: #fff;
  border: 0;
  border-radius: 5px 0 0 5px;
  box-shadow: -2px 0 1px 0 rgba(0, 0, 0, .05);
  -moz-transition: all .3s;
  -o-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ch-sidebar-title {
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #606266;
  margin-bottom: 15px
}

.ch-account-container {
  width: 370px;
  height: 215px
}

@media only screen and (min-width: 769px) {
  .ch-account-container.has-search {
    height: 250px
  }
}

@media only screen and (max-width: 768px) {
  .ch-account-container {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 65px !important;
    left: 0 !important;
    z-index: 2
  }

  .ch-account-container.has-search {
    top: 0 !important
  }
}

.ch-account-header {
  display: flex;
  align-items: center;
  height: 50px;
  padding-right: 15px;
  padding-left: 20px;
  font-size: 1em;
  border-bottom: 1px solid #edeff2
}

.ch-account-header .ch-search-form-input {
  font-size: 1em;
  margin-left: 15px
}

.ch-account-header > .actions {
  margin-left: auto
}

.ch-account-header > .actions .btn-close {
  margin-left: 15px
}

@media only screen and (max-width: 768px) {
  .ch-account-header {
    height: 60px
  }

  .ch-account-header > .actions {
    display: flex
  }
}

.ch-account-content {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0
}

.ch-account-content-menu, .ch-account-content-submenu {
  width: 50%;
  height: 100%;
  overflow: hidden
}

.ch-account-content-menu .ch-dropdown-menu {
  padding-right: 20px;
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: hidden;
  box-sizing: content-box
}

.ch-account-content-menu .ch-dropdown-menu:hover {
  overflow-y: auto
}

.ch-account-content-menu > .ch-dropdown-menu {
  max-height: 100%
}

.ch-account-content-menu > .ch-dropdown-menu .ch-dropdown-menu-item {
  position: initial
}

.ch-account-content-menu > .ch-dropdown-menu .ch-dropdown-menu-item > .ch-dropdown-menu-item-label {
  display: block;
  padding: 10px 25px;
  font-weight: 400;
  text-align: left;
  text-transform: none;
  color: inherit
}

.ch-account-content-menu > .ch-dropdown-menu .ch-dropdown-menu-item > .ch-dropdown-menu-item-label:hover {
  color: #777a7f
}

.ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label {
  position: relative;
  font-weight: 700
}

.ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: auto;
  height: 20px;
  border-right: 5px solid #00e2f6;
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}

.ch-account-content-menu > .ch-dropdown-menu > .ch-dropdown-menu-item.open > .ch-dropdown-menu-item-label, .ch-account-content-submenu > .ch-dropdown-menu > .ch-dropdown-menu-item.active > .ch-dropdown-menu-item-label {
  font-weight: 700
}

.ch-account-content-submenu {
  position: absolute;
  top: 0;
  left: 50%;
  font-weight: 400;
  box-shadow: 10px 0 10px -10px rgba(0, 0, 0, .15) inset
}

@media only screen and (max-width: 768px) {
  .ch-account-content {
    height: 100%
  }
}

.ch-account-header + .ch-account-content {
  height: calc(100% - 50px)
}

@media only screen and (max-width: 768px) {
  .ch-account-header + .ch-account-content {
    height: calc(100% - 65px)
  }
}

.ch-account .ch-dropdown-toggle {
  white-space: nowrap;
  display: flex;
  align-items: center
}

.ch-account .ch-dropdown-toggle .fa-icon.dropdown {
  -moz-transition: all .3s;
  -o-transition: all .3s;
  -webkit-transition: all .3s;
  transition: all .3s
}

.ch-account .ch-dropdown-menu-item .ch-account-content-submenu {
  display: none
}

.ch-account .ch-dropdown-menu-item.open .ch-account-content-submenu {
  display: block
}

.ch-account.active > .ch-dropdown-toggle .fa-icon.dropdown {
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.ch-blankslate, .ch-error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%
}

.ch-blankslate {
  min-height: calc(90vh - 65px)
}

.ch-user-header .avatar {
  width: 54px;
  border-radius: 100%
}

.ch-user-header .info {
  overflow-x: hidden
}

.ch-user-header .email, .ch-user-header .name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.ch-user-header .email {
  font-size: .95em;
  color: #777a7f
}

.ch-user-container .ch-user-action, .ch-user-container .ch-user-header {
  position: relative;
  margin: 20px 0
}

.ch-user-container .ch-user-action a, .ch-user-container .ch-user-header {
  display: flex;
  align-items: center;
  color: #47494c
}

.ch-user-container .ch-user-action a > .fa-icon, .ch-user-container .ch-user-header > .avatar {
  width: 54px;
  margin-left: 20px;
  margin-right: 10px;
  text-align: center;
  color: inherit
}

.ch-user-container .ch-user-action a > .fa-icon {
  font-size: 1.15em
}

.ch-user-container .info, .ch-user-container .label {
  padding-right: 20px
}

.ch-user .ch-dropdown-content {
  width: 330px
}

@media only screen and (max-width: 480px) {
  .ch-user .ch-dropdown-content {
    position: fixed;
    width: 100%;
    top: 60px;
    left: 0;
    right: auto
  }
}

.ch-error-page {
  height: 100%;
  padding: 20px;
  font-size: 14px;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  background: #f9f9f9
}

.ch-error-page-number {
  position: relative;
  font-size: 10em;
  line-height: 1em
}

.ch-error-page-number-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: -30px;
  padding: 8px;
  font-size: 1rem;
  color: #fff;
  background: #00cae5;
  border-radius: 100%
}

.ch-error-page-header {
  font-weight: 600;
  font-size: 2.15em;
  line-height: 1.5em
}

.ch-error-page-message {
  font-size: 1.15em
}

.ch-error-page-cta {
  display: inline-block;
  margin-top: 3em;
  font-size: 1.2em;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  padding: 10px 40px;
  background: #00cae5;
  border-radius: 2px
}

.ch-error-page-cta:active, .ch-error-page-cta:hover {
  color: #fff
}

.ch-error-page-footer {
  position: absolute;
  width: 100%;
  height: 65px;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  border-top: 1px solid #e0e0e0
}

.ch-error-page-footer-logo {
  width: auto;
  height: 30px;
  opacity: .2
}

@media only screen and (max-width: 768px) {
  .ch-error-page {
    font-size: 11px
  }

  .ch-error-page-message {
    font-size: 1.2em
  }
}
